import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
`

export const Centered = styled.div`
  align-self: center;
  text-align: center;
  width: 100%;
`
