import React from 'react'
import { Layout, SEO } from 'components/common'
import NotFound from 'components/landing/NotFound'

export default () => (
  <Layout>
    <SEO title="404" location="/404" />
    <NotFound />
  </Layout>
)
