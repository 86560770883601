import React from 'react'
import { Link } from 'gatsby'
import { Container, Button } from 'components/common'
import { Wrapper, Centered } from './styles'

export default () => (
  <Wrapper as={Container}>
    <Centered>
      <h1>Page Not Found</h1>
      <Button as={Link} to="/">
        Go back home
      </Button>
    </Centered>
  </Wrapper>
)
